import { Helmet, HelmetProvider } from "react-helmet-async";
import Markdown from "react-markdown";

const markdown = `
株式会社SIGQ（以下、当社）は、お客様からお預かりしたデータ・自社のデータ問わず、すべての情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。 

### 1.経営者の責任
当社は、経営者主導で組織的かつ継続的に情報セキュリティの改善・向上に努めます。

### 2.社内体制の整備
当社は、情報セキュリティの維持および改善を目的とし、必要な対策を明確にし、日常業務のルールとして定めます。情報の保護と適切な管理に努め、継続的な見直しを行います。

### 3.従業員の取組み
当社の従業員は、情報セキュリティのために必要とされる知識、技術を習得し、情報セキュリティへの取り組みを確かなものにします。

### 4.法令及び契約上の要求事項の遵守
当社は、情報セキュリティに関わる法令、規制、規範、契約上の義務を遵守するとともに、お客様の期待に応えます。

### 5.違反及び事故への対応

当社は、情報セキュリティに関わる法令違反、契約違反及び事故が発生した場合には適切に対処し、再発防止に努めます。

制定日:2024年12月5日  
株式会社SIGQ  
代表 金築 敬晃 
`;

export default function Security() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>SIGQ - 情報セキュリティ基本方針</title>
        <meta name="description" content="法律の定める公告を掲示しています。" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="px-4 sm:px-6 lg:px-8 w-[70%] md:w-[80%] m-auto mt-10">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900 text-center">
              情報セキュリティ基本方針
            </h1>
          </div>
        </div>
        <div className="px-4 sm:px-6 lg:px-8 w-[90%] md:w-[60%] xl:w-[80%] m-auto mt-20 mb-20">
          <Markdown className="prose">{markdown}</Markdown>
        </div>
      </div>
    </HelmetProvider>
  );
}
