import {
  ArrowPathIcon,
  PencilIcon,
  PresentationChartBarIcon,
} from "@heroicons/react/20/solid";
import { Helmet, HelmetProvider } from "react-helmet-async";

const features = [
  {
    name: "地域課題のヒアリング",
    description:
      "どのような課題で皆様がお困りなのかをヒアリングするところからスタートします。",
    icon: PencilIcon,
  },
  {
    name: "課題解決方法の提案",
    description:
      "ヒアリング内容をもとに、完全オーダーメードで課題の改善に役立ちそうな方法を考え、ご提案します。",
    icon: PresentationChartBarIcon,
  },
  {
    name: "地域への導入",
    description:
      "ITツールを単に開発・導入するだけで解決できる課題はほんの一部しかありません。ツール導入ではなく、社会課題を改善する方法を地域の皆さんとともに考え、導入していきます。",
    icon: ArrowPathIcon,
  },
];

export default function SicialDesignLab() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>SIGQ - ソーシャルデザインラボ</title>
        <meta
          name="description"
          content="地域社会に存在する多様な課題を、各地域で生活をされている方と一丸となって解決する事業です。"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-base/7 font-semibold text-indigo-600">
              社会課題解決事業
            </h2>
            <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-5xl">
              ソーシャルデザインラボ
            </p>
            <p className="mt-6 text-lg/8 text-gray-600">
              地域社会に存在する多様な課題を、各地域で生活をされている方と一丸となって解決する事業です。
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden pt-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              alt="App screenshot"
              src="/social-design-lab.png"
              width={2432}
              height={1442}
              className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            />
            <div aria-hidden="true" className="relative">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
            </div>
          </div>
        </div>
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
          <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base/7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  <feature.icon
                    aria-hidden="true"
                    className="absolute left-1 top-1 size-5 text-indigo-600"
                  />
                  {feature.name}
                </dt>{" "}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </HelmetProvider>
  );
}
